import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import { defaultColor } from "../src/constants";
import { useLocalStorage } from "../src/hooks/useLocalStorage";
import AppLoader from "./AppLoader";
import { SeenEnum } from "../src/enum";
import { onboardingTextClasses } from "../src/theme";
import { AppLogoHeader } from "./AppLogoHeader";
import OnboardingNextButton from "./OnboardingNextButton";
import Image from "next/image";
import useDeviceDetect from "../src/hooks/useDeviceDetect";
import { AppLogo } from "./AppLogo";
import { logOnboardingOpened } from "../src/services/yandexMetrikaEvents";

export default function Onboarding() {
  const router = useRouter();

  const [seen, setSeen] = useLocalStorage<string>("ez_seen", SeenEnum.no);

  const [step, setStep] = useState(0);

  const isDesktopEnabled = true;
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    if (seen === SeenEnum.yes) {
      router.push("/games");
      return;
    }

    if (step == 0 && seen === SeenEnum.no) {
      // set step 1
      setStep(1);
      logOnboardingOpened();
    }
  }, [seen]);

  const nextStep = async () => {
    // если вопрос предпоследний проверяем
    if (step === steps.length - 2 && (isMobile || isDesktopEnabled)) {
      setSeen(SeenEnum.yes);
      await router.push("/games");
      return;
    }

    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };

  const subtitleClassNames = "text-center mb-10 text-[#4F4F4F] text-xl mx-20";

  const step0 = <AppLoader />;

  const step1 = (
    <>
      <AppLogoHeader color={"rgba(0, 0, 0, 0.4)"} />

      <div className={onboardingTextClasses}>
        <div className="block md:px-40 lg:px-80 xl:px-80 2xl:px-160 font-semibold">
          Перед вами карточная
          <br /> игра с глубокими
          <br /> вопросами
        </div>

        <div className="mt-7 block md:px-40 lg:px-80 xl:px-80 2xl:px-160">
          Искренне отвечая на них,
          <br /> вы можете выйти на новый
          <br /> уровень отношений и <br />
          взаимопонимания с<br /> партнером, друзьями или
          <br /> даже с самим собой
        </div>

        <div className="mt-7">
          <Image src="/<3.svg" width="24" height="24" />
        </div>

        <OnboardingNextButton color={defaultColor} />
      </div>
    </>
  );

  const step2 = (
    <>
      <AppLogoHeader color={"rgba(0, 0, 0, 0.4)"} />

      <div className={onboardingTextClasses}>
        <div className="block md:px-40 lg:px-80 xl:px-80 2xl:px-160 font-semibold">
          Играйте прямо со своего телефона
        </div>

        <div className="mt-7 block md:px-40 lg:px-80 xl:px-80 2xl:px-160">
          Получите полный набор <br />
          виртуальных карточек, <br />
          который останется с вами
          <br /> навсегда
        </div>

        <div className="mt-7 block md:px-40 lg:px-80 xl:px-80 2xl:px-160">
          Не нужно ждать доставки,
          <br /> играйте с любого устройства <br />
          или браузера
        </div>

        <div className="mt-12 -mb-12 px-5  block md:px-40 lg:px-80 xl:px-80 2xl:px-160 text-[0.9rem] leading-[1rem] text-[#999999]">
          Если у вас остались вопросы,
          <br /> вы можете обратиться в <br />
          <span className="font-semibold">чат поддержки</span> или посетить
          <br />
          <span className="font-semibold">раздел с частыми вопросами</span>
          <br /> в меню
        </div>

        <OnboardingNextButton color="#B0B0B0" />
      </div>
    </>
  );

  const step6 = (
    <div className="mt-5 mb-5">
      <div className={subtitleClassNames}>
        Для продолжения <br /> зайди с мобильного
      </div>
      <div className={clsx(onboardingTextClasses)}>
        <Image
          src="/desktop.png"
          width={800}
          height={400}
          objectFit="contain"
        />
      </div>
      <div className="text-center mt-5">
        <div>
          <Image src="/qr.jpg" width={100} height={100} />
        </div>
        <div className="mt-3">
          <AppLogo color="rgba(0, 0, 0, 0.4)" />
        </div>
      </div>
    </div>
  );

  // skip step5
  const steps = [step0, step1, step2, step6];

  return (
    <>
      <div className="m-auto" onClick={nextStep}>
        {steps[step]}
      </div>
      <div className="absolute w-full h-full z-20" onClick={nextStep} />
    </>
  );
}
