import Head from "next/head";
import Onboarding from "../components/Onboarding";
import { NextPageWithLayout } from "./_app";
import { ReactElement, useEffect } from "react";
import SimpleLayout from "../layouts/SimpleLayout";
import {
  metaDescription,
  metaOgDescription,
  metaOgImage,
  metaOgImageHeight,
  metaOgImageWidth,
  metaOgTitle,
  metaTitle,
} from "../src/constants";

const HomePage: NextPageWithLayout = () => {
  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:image" content={metaOgImage} />
        <meta property="og:image:width" content={metaOgImageWidth} />
        <meta property="og:image:height" content={metaOgImageHeight} />

        <meta property="og:title" content={metaOgTitle} />
        <meta property="og:description" content={metaOgDescription} />
      </Head>
      <Onboarding />
    </>
  );
};

HomePage.getLayout = function getLayout(page: ReactElement) {
  return <SimpleLayout>{page}</SimpleLayout>;
};

export default HomePage;
