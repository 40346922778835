const yandexMetrikaId = process.env.NEXT_PUBLIC_YANDEX_METRIKA_ID ?? "";

const onboardingOpened = "onboarding_opened";
const gamesOpened = "games_opened";
const rulesOpened = "rules_opened";
const paymentLoadingPageOpened = "payment_loading_page_opened";
const paymentSuccessPageOpened = "payment_success_page_opened";
const paymentFailedPageOpened = "payment_failed_page_opened";

export function logOnboardingOpened() {
  logYandexEvent(onboardingOpened);
}
export function logGamesOpened() {
  logYandexEvent(gamesOpened);
}
export function logRulesOpened() {
  logYandexEvent(rulesOpened);
}
export function logPaymentLoadingPageOpened() {
  logYandexEvent(paymentLoadingPageOpened);
}
export function logPaymentSuccessPageOpened() {
  logYandexEvent(paymentSuccessPageOpened);
}
export function logPaymentFailedPageOpened() {
  logYandexEvent(paymentFailedPageOpened);
}

export function logGamePageOpened(slug: string) {
  logYandexEvent(`games_${slug}_opened`);
}

export function logGamePlayPageOpened(slug: string) {
  logYandexEvent(`games_${slug}_play_opened`);
}
export function logPaywallPageOpened(slug: string) {
  logYandexEvent(`paywall_${slug}_page_opened`);
}
export function logBuyGameButtonPressed(slug: string) {
  logYandexEvent(`buy_${slug}_button_pressed`);
}
export function logBuyFromPaywallPressed(slug: string) {
  logYandexEvent(`buy_from_paywall_${slug}`);
}

export function logYandexEvent(event: string, params?: Object) {
  try {
    // @ts-ignore
    window.ym(yandexMetrikaId, "reachGoal", event, params);
  } catch (e) {
    console.log(e);
  }
}
