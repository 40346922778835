import { Property } from "csstype";
import Link from "next/link";
import React from "react";
import { AppLogo } from "./AppLogo";
import Color = Property.Color;

export function AppLogoHeader({ color }: { color: Color }) {
  return (
    <div className="absolute left-1/2 -translate-x-1/2 top-0 mt-4 z-30">
      <AppLogo color={color} />
    </div>
  );
}
