export const mainTextPadding =
  "m-auto px-10 sm:px-20 md:px-40 lg:px-80 xl:px-80 2xl:px-80";

export const onboardingTextClasses =
  "text-center sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-[1.1rem] leading-[1.3rem] px-10";

export const cardTitleClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold text-center text-lg text-2xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2x uppercase";

export const cardPaywallClasses =
  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold text-center uppercase";
