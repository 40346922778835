import { Property } from "csstype";
import Link from "next/link";
import React from "react";
import Color = Property.Color;

export function AppLogo({ color }: { color: Color }) {
  return (
    <Link href="/">
      <a
        className="bg-white-900 px-3 my-5 rounded-md text-[1.2rem] z-30 sm:text-lg"
        style={{ color: color }}
      >
        eto&nbsp;znak&nbsp;<span className="font-medium">game</span>
      </a>
    </Link>
  );
}
