import { ChevronRightIcon } from "@heroicons/react/outline";
import React from "react";

export default function OnboardingNextButton({ color }: { color: string }) {
  return (
    <div className="absolute right-1 top-1/2 -translate-y-1/2">
      <ChevronRightIcon
        className="h-10 w-10 lg:h-12 lg:w-12 m-auto stroke-1"
        style={{ color }}
      />
    </div>
  );
}
